import { instance, setHeader } from "./auth-api";

const profileUri = "/profile";

export const profileAPI = {
  getProfile: async () => {
    const response = await instance.get("users/myself", { headers: setHeader() });
    return response;
  },
  updateProfile: async (userData) => {
    const response = await instance.put(profileUri, userData, { headers: setHeader() });
    return response;
  },
  upgradeSimulator: async () => {
    const response = await instance.post("registration/goto", {}, { headers: setHeader() });
    return response;
  },
}

