// @ts-nocheck
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { documentThunks } from "actions-thunks/documents-thunks";
import ToastComponent from "../ui-kit/toast/Toast";
import Popup from "../ui-kit/popup/Popup";
import { authThunks } from "actions-thunks/auth-thunks";
import SidebarNavigation from "ui/sidebar-navigation/SiIdebarNavigation";
import { navigationList } from "ui/sidebar-navigation/helpers";

import "./default-layout.scss";

const DefaultLayout = ({
  component: Component,
  setFieldIsEditing,
  fieldIsEditing,
  setPopupState,
  pickedDocument,
  portalWorkflowType,
  popupState,
  redirectItem,
  listOfDocuments,
}) => {
  const [currentAttachmentClicked, setCurrentAttachmentClicked] =
    useState(null);
  const [triggerSubmitData, setTriggerSubmitData] = useState(false);
  const [wireSent, setWireSent] = useState(null);
  const [wireSentClicked, setWireSentClicked] = useState(false);
  const [activeSidebarItem, setActiveSidebarItem] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const found = navigationList.find(
      (i) =>
        `/${i.destination.split("/")[1]}` ===
        `/${location?.pathname.split("/")[1]}`
    );
    if (found) {
      setActiveSidebarItem(found);
    }
  }, [location]);

  //handle popup button click
  const onPopupClickHandler = (type) => {
    const fnToCall = () => {
      switch (redirectItem.type) {
        case "upper":
          return onSideItemClick(redirectItem.item, true);
        case "logout":
          return dispatch(authThunks.logoutTC());
        default:
          break;
      }
    };
    switch (type) {
      case "account-wire":
        setWireSent(true);
        setPopupState({ opened: false, message: "", type: "" });
        break;
      case "remove-attachment":
        dispatch(
          documentThunks.removeAttachmentTC(
            pickedDocument?.id,
            currentAttachmentClicked
          )
        );
        setPopupState({ opened: false, message: "", type: "" });
        break;
      case "save":
        setFieldIsEditing({ status: false, type: "" });
        setTriggerSubmitData(true);
        fnToCall();
        setPopupState({ opened: false, message: "", type: "" });
        break;
      case "notsave":
        setFieldIsEditing({ status: false, type: "" });
        fnToCall();
        setPopupState({ opened: false, message: "", type: "" });
        break;
      case "stay":
        setPopupState({ opened: false, message: "", type: "" });
        break;
      default:
        break;
    }
  };

  const onSideItemClick = (item) => {
    if (item) {
      setActiveSidebarItem(item);
    }
  };

  return (
    <div className="default-layout-block">
      <SidebarNavigation 
        portalWorkflowType={portalWorkflowType}
        onSideItemClick={onSideItemClick}
        activeSidebarItem={activeSidebarItem}
      />
      <div className="main-body-block">
        <Component
          setFieldIsEditing={setFieldIsEditing}
          portalWorkflowType={portalWorkflowType}
          fieldIsEditing={fieldIsEditing}
          wireSent={wireSent}
          listOfDocuments={listOfDocuments}
          onSideItemClick={onSideItemClick}
          wireSentClicked={wireSentClicked}
          setWireSentClicked={setWireSentClicked}
          setWireSent={setWireSent}
          triggerSubmitData={triggerSubmitData}
          setTriggerSubmitData={setTriggerSubmitData}
          setPopupState={setPopupState}
          setCurrentAttachmentClicked={setCurrentAttachmentClicked}
        />
      </div>
      <Popup
        popupState={popupState}
        fieldIsEditing={fieldIsEditing}
        onPopupClickHandler={onPopupClickHandler}
      />
      <ToastComponent />
    </div>
  );
};

export default DefaultLayout;
