import { Socket, Manager } from 'socket.io-client';

type SocketVariants = "chat" | "application";

export class SocketClient {
  private manager: Manager;
  private sockets: Map<string, Socket>;

  constructor() {
    this.sockets = new Map();
  }

  initializeManager(): void {
    this.manager = new Manager(process.env.REACT_APP_TRADERAPI, {
      path: process.env.REACT_APP_SOCKETPATH,
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem("webSocketToken") || null}`
      }
    });
  }

  disconnectManager(): void {
    this.sockets.forEach((socket) => {
      socket.disconnect();
    });
    this.manager = null;
  }

  addNamespace(namespace: SocketVariants): void {
    const socket = this.manager.socket('/' + namespace);
    this.sockets.set(namespace, socket);
  }

  removeNamespace(namespace: SocketVariants): void {
    const socket = this.sockets.get(namespace);
    if (socket) {
      socket.disconnect();
      this.sockets.delete(namespace);
    }
  }

  emit(namespace: SocketVariants, eventName: string, data: any): void {
    const socket = this.sockets.get(namespace);
    if (socket) {
      socket.emit(eventName, data);
    }
  }

  on(namespace: SocketVariants, eventName: string, cb: (message: string) => void): void {
    const socket = this.sockets.get(namespace);
    if (socket) {
      socket.on(eventName, cb);
    }
  }
}