/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import MarketDataItem from "./rendering-parts/MarketDataItem";
import Bundles from "./rendering-parts/Bundles";
import MarketConfirmWindow from "./rendering-parts/modal/confirmation/modal";
import constants from "lib/constants";
import { accountThunks } from "actions-thunks/account-thunks";
import { utilityFunctions } from "../utilities/utilityFunctions";
import { ModalContext } from "ui/ui-kit/modal/ModalContext";
import { saveRequestedMarketsAC } from "actions-thunks/actions";
import { requestsThunks } from "actions-thunks/requests-thunks";
import { marketdataThunks } from "actions-thunks/marketdata-thunks";
import useSocketNamespace from "ui/hooks/useSocket";
import useStatistic from "ui/hooks/useStatistic";
import {
  getTPRefByName,
  getBundleName,
  setToPrimaryDataHandler,
  onMarketStatusChangeHandler,
  getMarketWithName,
} from "./helpers";

import "./marketdata.scss";

const MarketDataPage = () => {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);

  const { currentAccount, extensions } = useSelector((state) => state.accounts);
  const { isInitializedSocket } = useSelector((state) => state.sockets);
  const { requestsItems } = useSelector((state) => state.requests);
  const {
    marketdataProviders,
    marketBundles,
    requestedMarketsData,
    tradingPlatforms,
  } = useSelector((state) => state.marketdata);

  const [buttonItem, setButtonItem] = useState(null);
  const [primaryBundle, setPrimaryBundle] = useState(null);
  const [primaryEntitlements, setPrimaryEntitlements] = useState([]);
  const [localState, setLocalState] = useState({});
  const [changingStatus, setChangingStatus] = useState(false);
  const [dataWasChanged, setDataWasChanged] = useState(false);
  const [currentPopupAction, setCurrentPopupAction] = useState(null);
  const [dynamicPadding, setDynamicPadding] = useState(0);

  const { RequestFormStatusTypes, RequestTypes } = constants;
  const marketDataRequestItems = requestsItems?.[RequestTypes.MARKETDATA];
  const {
    updateChangeMarketsRequestTC,
  } = marketdataThunks;

  let bundleButtons = [];

  if (marketBundles) {
    bundleButtons = marketBundles.map((m, inx) => {
      return { name: m.name, value: inx };
    });
    bundleButtons.push({
      name: "Custom",
      value: bundleButtons.length,
      disabled: true,
    });
  }

  const setChosenButton = (markets) => {
    const name = getBundleName({
      markets,
      marketdataProviders,
      currentAccount,
      tradingPlatforms,
      marketBundles,
      setButtonItem,
    });
    setButtonItem(name);
    return name;
  };

  const getRequestedEntitlements = () => {
    return setToPrimaryDataHandler({
      requestedMarketsData,
      setLocalState,
      localState,
      setPrimaryEntitlements,
      extensions,
      getMarkets: true,
    });
  }

  const setToPrimaryData = () => {
    setToPrimaryDataHandler({
      requestedMarketsData,
      setLocalState,
      localState,
      setPrimaryEntitlements,
      extensions,
    });
  };

  const onMarketStatusChange = (name, value, reference, depRef) => {
    // TODO: check comment in helpers function
    onMarketStatusChangeHandler({
      name,
      value,
      reference,
      depRef,
      localState,
      setLocalState,
      filterMarketsToTradingPlatform,
      primaryEntitlements,
      setDataWasChanged,
      requestedMarketsData,
      blockRequest,
      setCurrentPopupAction,
      showModal,
      hideModal,
      setChosenButton,
    });
  };

  useSocketNamespace(currentAccount, "applications", isInitializedSocket);
  useStatistic(currentAccount?._id, "Open page Market Data");

  useEffect(() => {
    if (!marketdataProviders) {
      dispatch(accountThunks.setEntityTC("marketdataproviders"));
    }
    if (!tradingPlatforms) {
      dispatch(accountThunks.setEntityTC("tradingplatforms"));
    }
  }, [marketdataProviders, tradingPlatforms]);

  //detect wether the request is currently blocked or not
  useEffect(() => {
    if (
      !changingStatus &&
      requestedMarketsData?.status === RequestFormStatusTypes.BLOCKED
    ) {
      setChangingStatus(true);
    }
    if (
      changingStatus &&
      requestedMarketsData?.status !== RequestFormStatusTypes.BLOCKED
    ) {
      setChangingStatus(false);
    }
  }, [changingStatus, requestedMarketsData]);

  // find and set main market request
  useEffect(() => {
    if (marketDataRequestItems?.length > -1 && !requestedMarketsData) {
      if (marketDataRequestItems.length === 0) {
        const fakeRequest = {
          status: RequestFormStatusTypes.COMPLETED,
          schedule: {
            duedate: new Date().toISOString(),
          },
        };
        dispatch(saveRequestedMarketsAC(fakeRequest));
      } else {
        let result = null;
        const notCanceledRequests = marketDataRequestItems.filter(
          (i) =>
            i.type === RequestTypes.MARKETDATA &&
            i.status !== RequestFormStatusTypes.CANCELED
        );
        result = notCanceledRequests.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        )[0];
        dispatch(saveRequestedMarketsAC(result));
      }
    }
  }, [
    requestedMarketsData,
    marketDataRequestItems,
    RequestFormStatusTypes.CANCELED,
    RequestTypes.MARKETDATA,
    dispatch,
    RequestFormStatusTypes.COMPLETED,
  ]);

  // set/get current user and acc requests
  useEffect(() => {
    if (!utilityFunctions.isEmptyObj(currentAccount)) {
      const requestData = {
        accID: currentAccount._id,
        type: RequestTypes.MARKETDATA,
      };
      dispatch(accountThunks.setAccountExtensionsTC(currentAccount._id));
      dispatch(requestsThunks.getAccountRequestsTC(requestData));
      setLocalState({
        ...localState,
        tradingPlatform: currentAccount.software,
      });
    }
  }, [currentAccount]);

  // after we set requested market request, we filter and sort markets
  useEffect(() => {
    if (requestedMarketsData && extensions) {
      setToPrimaryData();
      if (requestedMarketsData.status === RequestFormStatusTypes.COMPLETED) {
        const ext = extensions.find(e => e.code === "trading/marketdata")?.ext?.markets.map(m => m.market);
        setPrimaryBundle(setChosenButton(ext))
        setButtonItem(null)
      }
    }
  }, [requestedMarketsData, extensions]);

  useEffect(() => {
    if (
      marketBundles &&
      tradingPlatforms &&
      primaryEntitlements &&
      marketdataProviders &&
      localState.markets &&
      !primaryBundle
    ) {
      const bundle = setChosenButton(localState.markets);
      const primBundle = setChosenButton(primaryEntitlements);
      setPrimaryBundle(primBundle);
      setButtonItem(bundle);
    }
  }, [
    marketBundles,
    primaryEntitlements,
    tradingPlatforms,
    marketdataProviders,
    localState.markets,
    primaryBundle,
    setChosenButton,
  ]);

  // => define behaviour after popup clicked "yes"
  useEffect(() => {
    if (currentPopupAction === "cancel-market-request") {
      const body = {
        date: localState.effectiveDate,
      };
      const completeRequests = marketDataRequestItems
        .map((i) =>
          i.type === RequestTypes.MARKETDATA &&
            i.status === RequestFormStatusTypes.COMPLETED
            ? i
            : null
        )
        .filter((n) => n)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      dispatch(
        updateChangeMarketsRequestTC(
          currentAccount._id,
          requestedMarketsData._id,
          body,
          completeRequests[0],
          "cancel"
        )
      );
      setCurrentPopupAction(null);
      setDataWasChanged(false);
    }
    if (currentPopupAction === "no-markets") {
      const newMarkets = [];
      const newRemoveMarkets = [];
      const newAddMarkets = [];
      filterMarketsToTradingPlatform.forEach((m) => {
        if (
          primaryEntitlements.includes(m.name) &&
          !newMarkets?.includes(m.name)
        )
          newRemoveMarkets.push(m.name);
        if (
          !primaryEntitlements?.includes(m.name) &&
          newMarkets?.includes(m.name)
        )
          newAddMarkets.push(m.name);
      });

      if (!newMarkets.length
        && requestedMarketsData.status !== RequestFormStatusTypes.BLOCKED
        && requestedMarketsData.status !== RequestFormStatusTypes.COMPLETED) {
        setDataWasChanged(false);
      }

      setLocalState({
        ...localState,
        markets: newMarkets,
        addMarkets: newAddMarkets,
        removeMarkets: newRemoveMarkets,
      });
      setChosenButton(newMarkets);
      setCurrentPopupAction(null);
    }
  }, [
    currentPopupAction,
    setCurrentPopupAction,
    RequestFormStatusTypes.COMPLETED,
    RequestTypes.MARKETDATA,
    tradingPlatforms,
    dispatch,
    requestedMarketsData,
    marketDataRequestItems,
    localState,
  ]);

  useEffect(() => {
    if (!localState.effectiveDate) {
      setLocalState((prevState) => ({
        ...prevState,
        effectiveDate: moment()
          .utc()
          .add(1, "M")
          .startOf("month")
          .format("YYYY-MM-DD"),
      }));
    }
  }, [localState.effectiveDate, setLocalState]);

  const filterMarketsToTradingPlatform = useMemo(() => {
    if (currentAccount && marketdataProviders) {
      return marketdataProviders.filter((m) =>
        m.tradingPlatformRefs.includes(
          getTPRefByName(
            currentAccount.accountType,
            currentAccount.software,
            currentAccount.softwareType,
            tradingPlatforms
          )
        )
      );
    }
  }, [currentAccount, tradingPlatforms, marketdataProviders]);

  const blockRequest = (openRequest) => {
    if (requestedMarketsData._id) {
      dispatch(
        updateChangeMarketsRequestTC(
          currentAccount._id,
          requestedMarketsData._id,
          {
            date: localState.effectiveDate,
          },
          false,
          openRequest ? "unblock" : "block"
        )
      );
    }
  };

  const handleRequestMarketBundleChange = ({ name }) => {
    const removeMarkets = [];
    const addMarkets = [];
    const newMarkets = getMarketWithName({
      filterMarketsToTradingPlatform,
      name,
    });

    filterMarketsToTradingPlatform.forEach((m) => {
      if (
        primaryEntitlements.includes(m.name) &&
        !newMarkets?.includes(m.name)
      ) {
        removeMarkets.push(m.name);
      }
      if (
        !primaryEntitlements?.includes(m.name) &&
        newMarkets?.includes(m.name)
      ) {
        addMarkets.push(m.name);
      }
    });

    setButtonItem(name);
    setChangingStatus(true);
    setDataWasChanged(true);

    setLocalState({
      ...localState,
      addMarkets,
      markets: newMarkets,
      removeMarkets,
    });

    requestedMarketsData.status === RequestFormStatusTypes.OPEN &&
      blockRequest();
  };

  const handleCancelMarketDataRequestModal = ({ markets }) => {
    setDataWasChanged(false);
    setLocalState({ ...localState, markets });
    setButtonItem(primaryBundle);
    setCurrentPopupAction("cancel-market-request");
    requestedMarketsData.status !== RequestFormStatusTypes.BLOCKED &&
      requestedMarketsData.status === RequestFormStatusTypes.OPEN &&
      blockRequest(true);
  };

  const handleDiscardMarketDataRequestModal = ({
    withDiscardingPrimaryButton,
  }) => {
    setToPrimaryData();
    withDiscardingPrimaryButton && setButtonItem(setChosenButton(getRequestedEntitlements()));
    setDataWasChanged(false);
    requestedMarketsData.status === RequestFormStatusTypes.BLOCKED &&
      blockRequest(true);
    requestedMarketsData.status !== RequestFormStatusTypes.BLOCKED &&
      requestedMarketsData.status === RequestFormStatusTypes.OPEN &&
      blockRequest();
  };

  const handleMarketDataRequestModalDateChange = ({ dateValue }) => {
    setDataWasChanged(true);
    setLocalState({ ...localState, effectiveDate: dateValue });
  };

  // TODO: too many deps here, so what for useMemo at all?
  const renderMarketsBlock = useMemo(() => {
    if (marketdataProviders) {
      let groups = [];
      for (let i = 0; i < marketdataProviders.length; i++) {
        const el = marketdataProviders[i];
        if (!groups.includes(el.group)) groups.push(el.group);
      }

      if (requestedMarketsData)
        return (
          <div className="marketdata-main-block">
            {groups.map((g, inx) => (
              <MarketDataItem
                title={g}
                key={g}
                primaryEntitlements={primaryEntitlements}
                marketdataProviders={marketdataProviders}
                onMarketStatusChange={onMarketStatusChange}
                entitlements={localState?.markets}
                marketItems={filterMarketsToTradingPlatform}
                changingStatus={changingStatus}
                requestedMarketsData={requestedMarketsData}
              />
            ))}
          </div>
        );
      return null;
    }
  }, [
    marketdataProviders,
    changingStatus,
    requestedMarketsData,
    localState.markets,
    primaryEntitlements,
    // TODO: do we really need marketBundles, currentAccount as deps here?
    currentAccount,
    marketBundles,
  ]);

  return (
    <div
      className="marketdata-wrapper"
      style={{ paddingBottom: dynamicPadding }}
    >
      <div className="marketdata-header-wrapper">
        <h2 className="marketdata-header">Entitlements</h2>
        <div className="marketdata-header-info">
          <span>
            TraderID:&nbsp;
            <span className="semi-bold">
              {currentAccount?.reference?.client || "N/A"}&nbsp;&nbsp; (
              <span className="greyed-text">
                {utilityFunctions.getAccountStatusText(currentAccount?.status)})
              </span>
            </span>
          </span>
          <span>
            Platform:&nbsp;
            <span className="semi-bold">
              {currentAccount?.software || "N/A"}&nbsp;
              {currentAccount?.softwareType || "N/A"}
            </span>
          </span>
        </div>
        {requestedMarketsData && (
          <Bundles
            bundle={bundleButtons}
            primaryBundle={primaryBundle}
            filterMarketsToTradingPlatform={filterMarketsToTradingPlatform}
            isBundlesDisabled={
              !changingStatus &&
              requestedMarketsData.status !== RequestFormStatusTypes.COMPLETED
            }
            handleRequestMarketBundleChange={handleRequestMarketBundleChange}
            buttonItem={buttonItem}
            requestedMarketsData={requestedMarketsData}
            changingStatus={changingStatus}
          />
        )}
      </div>
      {renderMarketsBlock}
      {requestedMarketsData && (
        <MarketConfirmWindow
          buttonItem={buttonItem}
          filterMarketsToTradingPlatform={filterMarketsToTradingPlatform}
          primaryEntitlements={primaryEntitlements}
          localState={localState}
          setDataWasChanged={setDataWasChanged}
          requestedMarketsData={requestedMarketsData}
          dataWasChanged={dataWasChanged}
          setDynamicPadding={setDynamicPadding}
          blockRequest={blockRequest}
          handleMarketDataRequestModalDateChange={
            handleMarketDataRequestModalDateChange
          }
          handleCancelMarketDataRequestModal={
            handleCancelMarketDataRequestModal
          }
          handleDiscardMarketDataRequestModal={
            handleDiscardMarketDataRequestModal
          }
        />
      )}
    </div>
  );
};

export default MarketDataPage;
