import momentTZ from "moment-timezone";
import moment from "moment";

export const utilityFunctions = {
  monthNames: () => [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  testedField: (name, value) => {
    switch (name) {
      case "personal.firstName":
      case "personal.middleName":
      case "personal.lastName":
      case "personal.citizenship":
      case "address.street":
      case "address.street2":
      case "address.country":
        return { [name]: utilityFunctions.validEnglishTextAndNumber(value) };
      case "address.city":
        return { [name]: utilityFunctions.validEnglishText(value) };
      case "address.postalCode":
        return { [name]: utilityFunctions.validIDNumber(value) };
      case "dateofbirth":
        return { [name]: !utilityFunctions.checkIfNotValidDateOfBirth(value) };
      case "document.idexpiration":
        return {
          [name]:
            !utilityFunctions.checkIfExpDateIsNotValid(value) &&
            !utilityFunctions.checkIfDateIsMore50(value),
        };
      case "document.idnumber":
        return { [name]: utilityFunctions.validIDNumber(value) };
      case "phone":
        return { [name]: utilityFunctions.phoneNumber(value) };
      default:
        return { [name]: true };
    }
  },
  strToBoolean: (s) => {
    s = String(s).toLowerCase();
    return s === "true" || s === "yes" || s === "1";
  },
  validIDNumber: (t) => {
    return typeof t === "string" && !!t.match(/^[A-Za-z0-9.\-#+:]+$/);
  },
  roundToNearest: (number, base) => {
    if (number % base >= base / 2) {
      return Math.ceil(number / base) * base;
    } else {
      return Math.floor(number / base) * base;
    }
  },
  formatDateToYYYYMMDD: (incomingDate) => {
    const year = incomingDate.getFullYear();
    const month = String(incomingDate.getMonth() + 1).padStart(2, "0");
    const day = String(incomingDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  },
  checkIfNotValidMarketdataRequest: (localValue, newMarkets, primaryMarkets) => {
    if (utilityFunctions.checkTwoMDArraysToIdentity(newMarkets, primaryMarkets)) {
      return true;
    }

    return (
      localValue === "Invalid date" ||
      momentTZ(localValue).isBefore(new Date(), "day") ||
      momentTZ(localValue).isAfter(momentTZ().add(1, "year"))
    );
  },
  checkTwoMDArraysToIdentity: (array1 = [], array2 = []) => {
    const newMarkets = array1;
    const array2Sorted = newMarkets?.slice().sort();
    return (
      array2?.length === newMarkets?.length &&
      array2
        .slice()
        .sort()
        .every((value, index) => value === array2Sorted[index])
    );
  },
  checkIfNotValidDateOfBirth: (localValue) => {
    const year = Number(momentTZ(localValue, "MM-DD-YYYY").format("YYYY"));
    const month = Number(momentTZ(localValue, "MM-DD-YYYY").format("M"));
    const day = Number(momentTZ(localValue, "MM-DD-YYYY").format("D"));
    return (
      localValue === "Invalid date" ||
      Number(localValue?.split("-")[2]) < 1900 ||
      (localValue !== undefined &&
        new Date(1903, 1, 1).getTime() - 31 * 86400 * 1000 >
          new Date(year, month, day).getTime()) ||
      (localValue !== undefined &&
        new Date().getTime() - (365 * 18 + 5) * 86400 * 1000 <
          new Date(year, month, day).getTime())
    );
  },
  phoneNumber: (t) => {
    return (
      (typeof t === "string" && !!t.match(/^[0-9+()\-.']*$/)) ||
      typeof t === "undefined" ||
      t.length === 0
    );
  },
  checkIfExpDateIsNotValid: (localValue) => {
    const year = Number(momentTZ(localValue, "MM-DD-YYYY").format("YYYY"));
    const month = Number(momentTZ(localValue, "MM-DD-YYYY").format("M"));
    const day = Number(momentTZ(localValue, "MM-DD-YYYY").format("D"));
    return (
      localValue &&
      !(
        new Date().getTime() + 90 * 86400 * 1000 <
        new Date(year, month, day).getTime()
      )
    );
  },
  checkIfDateIsMore50: (value) => {
    return !(
      new Date(value).getTime() <
      new Date().getTime() + 50 * 365 * 86400 * 1000
    );
  },
  getAccountStatusText: (accountStatus) => {
    if (accountStatus) {
      if (accountStatus === "AccountEnabled")
        return <span className="greyed-text">enabled</span>;
      return <span className="account-disabled-status">disabled</span>;
    } else {
      return "N/A";
    }
  },
  //check for navigation item permission
  checkPermission: (permissionToCheck, profileData) =>
    profileData.permissions && profileData.permissions[permissionToCheck],
  //check for document permission
  checkDocumentPermission: (permissionToCheck, profileData) => {
    const toLowerPermission = permissionToCheck
      ? `document.${permissionToCheck?.toLowerCase()}`
      : "document";
    return (
      profileData.permissions && profileData.permissions[toLowerPermission]
    );
  },
  sortNavigationBarArray: (arrayToSort) => {
    const withOrders = [];
    const noOrders = [];
    arrayToSort.forEach((item) => {
      if (typeof item.order === "number") {
        withOrders.push(item);
      } else noOrders.push(item);
    });
    withOrders.sort((a, b) => {
      if (a.order !== b.order) return a.order - b.order;
      return a.summary - b.summary;
    });
    noOrders.sort((a, b) => a.summary?.localeCompare(b.summary));
    return withOrders.concat(noOrders);
  },
  validEnglishTextAndNumber: (t) => {
    return (
      (typeof t === "string" && !!t.match(/^[A-Za-z0-9 \-.,']*$/)) ||
      typeof t === "undefined"
    );
  },
  validEnglishText: (t) => {
    return (
      (typeof t === "string" && !!t.match(/^[A-Za-z \-.']*$/)) ||
      typeof t === "undefined"
    );
  },
  processCSV: (str, delim = ";") => {
    let headers = str.slice(0, str.indexOf("\n")).split(delim);
    let rows = str.split("\n").filter((n) => n);
    const polishedRows = rows.map((i) =>
      i
        .split("")
        .filter((i) => i !== "\r")
        .join("")
    );
    const newArray = polishedRows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    return newArray;
  },
  extensionsTypes: [
    "AIFF",
    "AIF",
    "AU",
    "AVI",
    "BAT",
    "BMP",
    "CLASS",
    "JAVA",
    "CSV",
    "DBF",
    "DIF",
    "DOC",
    "DOCX",
    "EPS",
    "GIF",
    "HQX",
    "HTM",
    "HTML",
    "JPG",
    "JPEG",
    "MAC",
    "MAP",
    "MDB",
    "MS",
    "MID",
    "MIDI",
    "MOV",
    "QT",
    "MTB",
    "MTW",
    "PDF",
    "P65",
    "PNG",
    "PPT",
    "PPTX",
    "PSD",
    "PSP",
    "QXD",
    "RA",
    "RTF",
    "SIT",
    "TAR",
    "TIF",
    "TIFF",
    "TXT",
    "WAV",
    "WK3",
    "WKS",
    "XLS",
    "XLSX",
    "ZIP",
  ],
  checkIsDuplicatedName: (
    arrayOfObjectsWithNames,
    pickedDocument,
    fileName
  ) => {
    const foundPickedDocumentInList = arrayOfObjectsWithNames.find(
      (item) => item.summary === pickedDocument.summary
    );

    if (foundPickedDocumentInList) {
      const eachChild =
        foundPickedDocumentInList.children &&
        foundPickedDocumentInList.children
          .map((item) => arrayOfObjectsWithNames.find((obj) => obj.id === item))
          .filter((n) => n)
          .map((item) => item.summary);
      return eachChild && eachChild.includes(fileName);
    }
    return false;
  },
  transformToNestedField: (fieldName, isImage) => {
    if (isImage) {
      return `document.scanFiles.${fieldName}`;
    }
    switch (fieldName) {
      case "city":
      case "country":
      case "postalCode":
      case "street":
      case "street2":
        return `address.${fieldName}`;
      case "idexpiration":
      case "idnumber":
      case "idtype":
        return `document.${fieldName}`;

      case "pep":
      case "details":
        return `pep.${fieldName}`;
      case "citizenship":
      case "firstName":
      case "lastName":
      case "middleName":
      case "title":
        return `personal.${fieldName}`;
      default:
        return fieldName;
    }
  },
  transfromFromNestedField: (field) => {
    if (field?.split(".").length > 1) {
      return field?.split(".").at(-1);
    }
    return field;
  },
  renderInfoForExampleImage: (name) => {
    switch (name) {
      case "identity":
        return "Upload a selfie holding your ID and a handwritten note with your name, date, and signature. Max: 5MB.";
      case "photoIDback":
        return "One piece of valid government-issued photo ID: Back (when applicable). Max: 5MB.";
      case "photoID":
        return "One piece of valid government-issued photo ID: Front. Max: 5MB.";
      case "address":
        return "Proof of physical address. Max: 5MB.";
      default:
        return "";
    }
  },
  //transform incomming date to view MMMM D, YYYY hh:mm A z
  transformToStepDateView: (date, noHours) => {
    if (noHours)
      return `${momentTZ(date).tz("America/New_York").format("MMMM D, YYYY")}`;
    return `${momentTZ(date)
      .tz("America/New_York")
      .format("MMMM D, YYYY hh:mm A z")}`;
  },
  nativeDateTransform: (date, backBirth = false) => {
    if (date) {
      if (date.split("T").length > 1) {
        return date.split("T")[0];
      }
      if (backBirth) {
        const month = date.split("-")[1];
        const day = date.split("-")[2];
        const year = date.split("-")[0];
        return `${month}-${day}-${year}`;
      }
      const month = date.split("-")[0];
      const day = date.split("-")[1];
      const year = date.split("-")[2];
      return `${year}-${month}-${day}`;
    }
  },
  isEmptyObj: (obj) => {
    return obj === undefined || obj === null
      ? true
      : Object.keys(obj).length === 0;
  },
  currencyFormatter: () => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  },
  userTime: (date = new Date(), format = "YYYY-MM-DD h:mm A", timeZone) => {
    if (timeZone) {
      return momentTZ(date).tz(timeZone).format(format);
    } else {
      return moment.utc(date).format(format);
    }
  },
  findKeyByValue: (object, value) => {
    for (const key in object) {
      if (object[key] === value) {
        return key;
      }
    }
    return null;
  },
  changeLabelView: (label) => {
    switch (label) {
      case "title":
        return "Salutation";
      case "pep":
        return "PEP Comment";
      case "phone":
        return "Phone Number (including country code)";
      case "street":
        return "Street Address";
      case "street2":
        return "Street Address (additional)";
      case "postalCode":
        return "ZIP/Postal code";
      case "idexpiration":
        return "Expiry date";
      case "address":
        return "Proof of Physical Address";
      case "identity":
        return "Self Photo";
      case "firstName":
        return "First Name";
      case "middleName":
        return "Middle Name";
      case "lastName":
        return "Last Name";
      case "dateofbirth":
        return "Date Of Birth";
      case "idtype":
        return "ID Type";
      case "idnumber":
        return "ID Number";
      case "performanceBond":
        return "Performance Bond Amount";
      case "maximumDailyLoss":
        return "maximum Daily Loss Limit";
      case "maximumTraderBalanceDrawDown":
        return "Maximum Trader Balance Draw Down";
      case "approvedOvernightBuyingPower":
        return "Approved Overnight Buying Power";
      case "initialBuyingPower":
        return "Initial Buying Power";
      case "tradingPlatformType":
        return "Trading Platform Type";
      case "tradingPlatform":
        return "Trading Platform";
      case "accountType":
        return "Account Type";

      default:
        return label;
    }
  },
  redirectToOnboarding: () => {
    const host = window.location.host.split(":")[0];
    const urls = {
      localhost: "https://dev.onboarding.zimtra.at-hub.com",
      "dev.portal.zimtra.at-hub.com": "https://dev.onboarding.zimtra.at-hub.com",
      "stage.portal.zimtra.ky": "https://stage.onboarding.zimtra.ky",
      "portal.zimtra.ky": "https://onboarding.zimtra.ky",
    };
  
    if (urls[host]) {
      window.location.replace(urls[host]);
    } else {
      window.location.replace("https://onboarding.zimtra.ky")
    }
  },
};
