const redirectToLanding = (path) => {
  const host = window.location.host.split(":")[0];
  const urls = {
    localhost: `https://dev.zimtra.at-hub.com/${path}`,
    "dev.portal.zimtra.at-hub.com": `https://dev.zimtra.at-hub.com/${path}`,
    "stage.portal.zimtra.ky": `https://stage.zimtra.ky/${path}`,
    "portal.zimtra.ky": `https://zimtra.ky/${path}`,
  };

  if (urls[host]) {
    window.location.replace(urls[host]);
  } else {
    window.location.replace("https://zimtra.ky")
  }
};

export { redirectToLanding };
