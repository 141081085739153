import { useEffect, useState, useMemo, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MenuContext, Icon } from "@lynx/ui-components";
import cn from "classnames";

import constants from "lib/constants";
import { accountThunks } from "actions-thunks/account-thunks";
import SettingsList from "ui/account-overview/risk-settings/RiskSettingsList";
import { NewRequest } from "./requests/NewRequest";
import AccountEnabledModal from "./modals/AccountEnabledModal";
import { utilityFunctions } from "ui/utilities/utilityFunctions";
import TableComponent from "ui/ui-kit/react-table/TableComponent";
import { requestsThunks } from "actions-thunks/requests-thunks";
import { ModalContext } from "ui/ui-kit/modal/ModalContext";
import { requestTableUtils } from "ui/utilities/requestsTableUtils";
import useSocketNamespace from "ui/hooks/useSocket";
import useStatistic from "ui/hooks/useStatistic";
import { supportTableUtils } from "ui/ui-kit/react-table/supportTableUtils";
import { Tooltip } from "react-tooltip";

import "./account-overview.scss";

const { tableFormattedData, setCellClassName, showInOverlay } =
  requestTableUtils;
const { RequestTypes, mappedAccountSummaryData } = constants;

const AccountOverviewPage = ({ onSideItemClick }) => {
  const { extensions, currentAccount } = useSelector((state) => state.accounts);
  const { isInitializedSocket } = useSelector((state) => state.sockets);
  const { requestsItems, riskSettingMetadata } = useSelector(
    (state) => state.requests
  );
  const [localExtensions, setLocalExtension] = useState(null);
  const [enabledWindowOpened, setEnabledWindowOpened] = useState(null);
  const { showModal, hideModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const requestsItemsDataALL = requestsItems?.[RequestTypes.ALL];

  useSocketNamespace(currentAccount, "applications", isInitializedSocket);
  useStatistic(currentAccount?._id, "Open page Account Overview");

  useEffect(() => {
    if (currentAccount && !!Object.keys(currentAccount).length) {
      dispatch(accountThunks.setAccountExtensionsTC(currentAccount._id));
      dispatch(requestsThunks.getRiskSettingsMetadataTC(currentAccount._id));
      dispatch(
        requestsThunks.getAccountRequestsTC({
          accID: currentAccount._id,
          statusCode: "unresolved",
        })
      );
      // show or now show congratulation window for the first time
      setEnabledWindowOpened(!currentAccount.isFirstModalShowed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  useEffect(() => {
    if (extensions) {
      setLocalExtension(
        extensions.find((e) => e.code === "summary/balance") || { noData: true }
      );
    }
  }, [extensions]);

  const setDateFormat = useMemo(() => {
    if (localExtensions)
      return localExtensions.noData
        ? "N/A"
        : utilityFunctions.transformToStepDateView(localExtensions.date);
  }, [localExtensions]);

  const renderAccountSummary = useMemo(() => {
    if (localExtensions?.noData) {
      return Object.entries(mappedAccountSummaryData).map((ext, index) => {
        return (
          <div key={`${index}-${ext[0]}`} className="this-month-body-item">
            <span>{ext[1]}</span>
            <span className="money-block">N/A</span>
          </div>
        );
      });
    }
    if (localExtensions) {
      return Object.entries(localExtensions.ext).map((ext, index) => {
        if (ext[0] !== "Account")
          return (
            <div key={`${index}-${ext[0]}`} className="this-month-body-item">
              <span>{mappedAccountSummaryData[ext[0]]}</span>
              <span className="money-block">
                {ext[0] === "Volume"
                  ? ext[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : utilityFunctions.currencyFormatter().format(ext[1])}
              </span>
            </div>
          );
        return null;
      });
    }
  }, [localExtensions]);

  const renderNoDataBlock = () => {
    if (
      tableFormattedData(true, requestsItemsDataALL, riskSettingMetadata).at(
        -1
      ) === "no-data"
    ) {
      return (
        <div className="account-overview-no-data-block">
          <span>No active requests</span>
        </div>
      );
    }
  };

  const cancelRequest = (data) => {
    const id = requestsItemsDataALL?.find(
      (r) => r.sequenceNumber === data.original.id
    )?._id;
    dispatch(
      requestsThunks.updateRequestStatusTC({
        accID: currentAccount._id,
        requestID: id,
        action: "cancel",
      })
    );
  };

  const showInOverlayHandler = (row, isLastItem) => {
    return showInOverlay({
      row,
      isLastItem,
      showModal,
      hideModal,
      cancelRequest,
      MenuContext,
      requestsItemsDataALL,
    });
  };

  if (localExtensions !== null && currentAccount)
    return (
      <div className="account-overview-wrapper">
        <div className="account-overview-header">
          <div className="account-overview-header-top">
            <h3>Account Overview</h3>
            <span
              className={cn({
                "pro-acc": currentAccount.accountType === "zimtrapro",
                "elite-acc": currentAccount.accountType === "zimtraelite",
                "expert-acc": currentAccount.accountType === "zimtraexpert",
              })}
            >
              {currentAccount.accountType === "zimtrapro"
                ? "Pro"
                : currentAccount.accountType === "zimtraexpert"
                ? "Expert"
                : "Elite"}
            </span>
          </div>
          <span>
            TraderID:&nbsp;
            <span className="semi-bold">
              {currentAccount?.reference?.client || "N/A"}&nbsp;&nbsp; (
              {utilityFunctions.getAccountStatusText(currentAccount?.status)})
            </span>
          </span>
          <span>
            Platform:&nbsp;
            <span className="semi-bold">
              {currentAccount?.software || "N/A"}&nbsp;
              {currentAccount?.softwareType || "N/A"}
            </span>
          </span>
          <span>updated on {setDateFormat}</span>
        </div>
        <div className="account-summary-block">
          <h6 className="account-title">
            So far this month
            <>
              <Icon
                className="info-icon"
                onClick={() => onSideItemClick("faq")}
                id="anchor-tooltip-so-far-this-month-title"
                name="info"
              />
              <Tooltip
                delayShow={250}
                delayHide={400}
                anchorSelect="#anchor-tooltip-so-far-this-month-title"
              >
                <span className="so-far-this-month-title-tooltip">Zimtra FAQ</span>
              </Tooltip>
            </>
          </h6>
          <div className="account-summary-body">{renderAccountSummary}</div>
        </div>
        <SettingsList currentAccount={currentAccount} />
        {enabledWindowOpened && (
          <AccountEnabledModal
            currentAccount={currentAccount}
            setEnabledWindowOpened={setEnabledWindowOpened}
          />
        )}
        {requestsItemsDataALL && (
          <div className="account-overview-table">
            <NewRequest
              isActiveRequestsTable
              renderFullListAfterRequest={false}
            />
            <div className="account-overview-table-body">
              <TableComponent
                data={tableFormattedData(
                  true,
                  requestsItemsDataALL,
                  riskSettingMetadata
                )}
                dateColumns={["created", "updated"]}
                formattingForDateColumns={supportTableUtils.tableTimeFormat}
                showSearch={false}
                showRowOverlay={showInOverlayHandler}
                setCellClassName={setCellClassName}
                itemsToUseSorting={[
                  "id",
                  "category",
                  "type",
                  "state",
                  "updated",
                  "created",
                ]}
              />
              {renderNoDataBlock()}
            </div>
          </div>
        )}
      </div>
    );

  return (
    <div className="account-setup-wrapper">
      <Spinner className="spinner" animation="grow" />
    </div>
  );
};

export default AccountOverviewPage;
