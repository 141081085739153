import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthAPI } from 'api/auth-api';
import { setIsAuthenticatedAC } from 'actions-thunks/actions';
import routesConfig from './routesConfig'; // Update the path as necessary

const AllRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if ((AuthAPI.getToken() || AuthAPI.getSessionToken()) && !isAuthenticated) {
      dispatch(setIsAuthenticatedAC(true));
    }
  }, [isAuthenticated, dispatch]);

  return (
    <Routes>
      {routesConfig.map((route, index) =>
        route.condition !== undefined ? (
          route.condition && <Route key={index} path={route.path} element={route.element} />
        ) : (
          <Route key={index} path={route.path} element={route.element} />
        )
      )}
    </Routes>
  );
};

export default AllRoutes;
