import cn from "classnames";
import { Tooltip } from "react-tooltip";
import { Icon } from "@lynx/ui-components";

import constants from "lib/constants";
import { setColorStatus, getRequiredRefs } from "../helpers";
import { utilityFunctions } from "ui/utilities/utilityFunctions";

const MarketDataItem = ({
  title,
  onMarketStatusChange,
  requestedMarketsData,
  changingStatus,
  primaryEntitlements,
  entitlements,
  marketItems,
  marketdataProviders,
}) => {
  const { RequestFormStatusTypes } = constants;

  const renderMarkets = () => {
    return marketItems
      ?.filter((m) => m.group === title)
      .map((item) => {
        const onCheckboxHandler = () =>
          onMarketStatusChange(
            item.name,
            !entitlements?.includes(item.name),
            item.reference,
            item.dependencyRefs
          );

        const requiredRefs = (arrayOfRefsToFind) =>
          getRequiredRefs(arrayOfRefsToFind, marketdataProviders)?.map(
            (name) => <span key={name}>{name}</span>
          );

        return (
          <div
            className={cn("market-item", {
              "added-item":
                !primaryEntitlements?.includes(item.name) &&
                entitlements?.includes(item.name),
              "removed-item":
                primaryEntitlements?.includes(item.name) &&
                !entitlements?.includes(item.name),
              disabled:
                !changingStatus &&
                requestedMarketsData.status !==
                  RequestFormStatusTypes.COMPLETED,
            })}
            key={item.name}
          >
            <input
              checked={entitlements?.includes(item.name) || false}
              type="checkbox"
              name={item.name}
              onChange={onCheckboxHandler}
            />
            <span
              onClick={onCheckboxHandler}
              className={`market-checkmark ${setColorStatus({
                name: item.name,
                primaryEntitlements,
                entitlements,
              })}`}
            ></span>
            <span>{item.name}</span>
            {!!item.dependencyRefs?.length && (
              <span className="market-item-dep-ref-info">
                <Icon id={`anchor-tooltip-${item._id}`} name="info" />
                <Tooltip
                  delayShow={250}
                  delayHide={400}
                  anchorSelect={`#anchor-tooltip-${item._id}`}
                >
                  <div className="i-text">
                    Requires {requiredRefs(item.dependencyRefs)}
                  </div>
                </Tooltip>
              </span>
            )}
            <span>
              &nbsp;{utilityFunctions.currencyFormatter().format(item.price)}
            </span>
          </div>
        );
      });
  };

  if (marketItems?.some((m) => m.group === title))
    return (
      <div className="marketdata-item-block">
        <div className="market-title">{title}</div>
        <div className="market-body">{renderMarkets()}</div>
      </div>
    );

  return null;
};

export default MarketDataItem;
