import { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { AuthAPI } from "../../api/auth-api";
import GetListLayoutWrapper from "../default-layout/GetListLayoutWrapper";
import { redirectToLanding } from "actions-thunks/helpers";
import {
  TO_ACCOUNT_OVERVIEW,
  TO_ACCOUNT_SETUP,
  TO_LOGIN,
  TO_PROFILE,
  TO_SIMULATOR,
} from "reducers/types";
import constants from "lib/constants";

const {
  loginizationsPaths,
  portalWorkflowTypeNames: { AccountOverview, AccountSetup, Simulator, Documents },
} = constants;

const PrivateRoute = ({
  component: Component,
  notActivatedAccountRoute,
  allTypesRoute,
  simulatorRoute,
  overviewRoute,
}) => {
  const { portalWorkflowType } = useSelector((state) => state.profile);
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState(null);

  useEffect(() => {
    if (typeof portalWorkflowType === "string") {
      if (portalWorkflowType === Documents && !allTypesRoute && location.pathname !== TO_PROFILE) {
        setRedirectPath(TO_PROFILE);
      } else if (portalWorkflowType === AccountOverview && !overviewRoute && !allTypesRoute && location.pathname !== TO_ACCOUNT_OVERVIEW) {
        setRedirectPath(TO_ACCOUNT_OVERVIEW);
      } else if (portalWorkflowType === AccountSetup && !notActivatedAccountRoute && !allTypesRoute && location.pathname !== TO_ACCOUNT_SETUP) {
        setRedirectPath(TO_ACCOUNT_SETUP);
      } else if (
        portalWorkflowType === Simulator &&
        !simulatorRoute &&
        (notActivatedAccountRoute || overviewRoute) &&
        !allTypesRoute &&
        location.pathname !== TO_SIMULATOR
      ) {
        setRedirectPath(TO_SIMULATOR);
      } else {
        setRedirectPath(null);
      }
    }
  }, [notActivatedAccountRoute, simulatorRoute, overviewRoute, allTypesRoute, portalWorkflowType, location.pathname]);


  if (redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  if (AuthAPI.getToken() || AuthAPI.getSessionToken()) {
    return <GetListLayoutWrapper component={Component} />;
  } else {
    if (window.location.hostname === "localhost") {
      // only for development purposes
      return <Navigate to={TO_LOGIN} state={{ from: location }} replace />;
    } else {
      redirectToLanding(loginizationsPaths.LOGOUT);
      return null;
    }
  }
};

export default PrivateRoute;
