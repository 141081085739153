import axios from "axios";
import Cookies from "js-cookie";

import { authThunks } from "actions-thunks/auth-thunks";
import { redirectToLanding } from "actions-thunks/helpers";
import constants from "lib/constants";
import { LOG_IN_TOKEN, TO_LOGIN } from "reducers/types";

export const AuthAPI = {
  login: async (userData) => {
    const response = await axios.post("https://dev.zimtra.at-hub.com/api/users/login", userData, {
      headers: setHeader(),
    });
    return response;
  },
  newPassword: async (userData) => {
    const response = await instance.put("/users/newpassword", userData, {
      headers: setHeader(),
    });
    return response;
  },
  getToken: () => {
    if (window.location.hostname === 'localhost') {
      return localStorage.getItem(LOG_IN_TOKEN)
    }
    if (Cookies.get(LOG_IN_TOKEN)) {
      return Cookies.get(LOG_IN_TOKEN);
    }
    return null;
  },
  getSessionToken: () => sessionStorage.getItem("token"),
};

export const setHeader = () => {
  if (AuthAPI.getToken()?.length > 1) {
    return { Authorization: `Bearer ${AuthAPI.getToken()}` };
  }
  return null;
};

const baseURL = process.env.REACT_APP_API_URL;
export const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use(null, (error) => {
  if (
    !axios.isCancel(error) &&
    error.response &&
    error.response.status === 401
  ) {
    authThunks.logout();
    if (window.location.hostname === "localhost") {
      // only for development purpuses
      window.location.replace(TO_LOGIN);
    } else {
      redirectToLanding(constants.loginizationsPaths.LOGOUT);
    }
  }
  return Promise.reject(error);
});
