import cn from "classnames";
import { NavLink } from "react-router-dom";

import { navigationList } from "./helpers";
import { PRIVACY_POLICY } from "reducers/types";

import styles from "./styles.module.scss";
import constants from "lib/constants";

const {
  portalWorkflowTypeNames,
  listForSimulatorAccount,
  listForNotEnabledAccount,
  listForEnabledAccount,
  listForDocumentsAccount,
} = constants;

const SidebarNavigation = ({
  portalWorkflowType,
  activeSidebarItem,
  onSideItemClick,
}) => {
  const filterListAccordingToApplicationStatus = () => {
    let listValue = null;
    if (portalWorkflowType === portalWorkflowTypeNames.Simulator)
      listValue = listForSimulatorAccount;
    if (portalWorkflowType === portalWorkflowTypeNames.AccountSetup)
      listValue = listForNotEnabledAccount;
    if (portalWorkflowType === portalWorkflowTypeNames.AccountOverview)
      listValue = listForEnabledAccount;
    if (portalWorkflowType === portalWorkflowTypeNames.Documents)
      listValue = listForDocumentsAccount;
    return listValue;
  };

  const filteredNavList = navigationList
    .map((i) =>
      filterListAccordingToApplicationStatus().includes(i.id) ? i : null
    )
    .filter((i) => i);

  const renderSideElements = filteredNavList.map((item) => {
    const isActive = activeSidebarItem?.id === item.id;
    return (
      <NavLink
        to={item.destination}
        onClick={() => onSideItemClick(item)}
        className={cn(styles["navlink-wrapper"], {
          [styles["active-link"]]: isActive,
        })}
        key={item.id}
      >
        <img
          width="35"
          height="30"
          src={isActive ? item["icon-active"] : item.icon}
          alt={item.summary}
        />
        {item.summary}
      </NavLink>
    );
  });

  return (
    <>
      {/* {burgerMenuOpened && <div className={styles["blacked-bg"]} />} */}
      <div
        className={
          styles["side-block"]
          // burgerMenuOpened ? "opened-menu" : "closed-menu"
        }
      >
        <div className={styles["fixed-side-elements"]}>
          <div className={styles["upper-side"]}>{renderSideElements}</div>
        </div>
        <div className={styles["copyright"]}>
          <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          <span>{`© ${new Date().getFullYear()} ZIMTRA, All Rights Reserved`}</span>
        </div>
      </div>
    </>
  );
};

export default SidebarNavigation;
