import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { Spinner } from "react-bootstrap";
import { ListObjectsV2Command, S3Client } from "@aws-sdk/client-s3";

import { setETBDocumentAC, setFreshestETBDocumentAC } from "actions-thunks/actions";
import AttachmentSearch from "ui/ui-kit/search/AttachmentSearch";
import AttachmentDownload from "ui/ui-kit/attachment/AttachmentDownload";

import styles from "./styles.module.scss";

const Resources = () => {
  const dispatch = useDispatch();
  const { ETBDocument, FreshestETBDocument } = useSelector(
    (state) => state.documents
  );
  const [files, setFiles] = useState(ETBDocument || []);

  const fetchFileList = async () => {
    const s3Client = new S3Client({
      region: "us-east-2",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    const bucketParams = {
      Bucket: "zimtra-publica-data",
      Prefix: "etb/",
    };

    try {
      const data = await s3Client.send(new ListObjectsV2Command(bucketParams));
      const fileNames = data.Contents.map((item) => item.Key);
      return fileNames;
    } catch (err) {
      console.log("Error fetching S3 objects:", err);
    }
  };

  useEffect(() => {
    const setFileList = async () => {
      try {
        const fileList = await fetchFileList();

        // Filter only the files that match the ETB_YYYYMMDD.csv pattern
        const etbFiles = fileList.filter(
          (name) => name.startsWith("etb/ETB_") && name.endsWith(".csv")
        );

        // Sort files by date (descending) and take the last 5
        const sortedFiles = etbFiles
          .sort((a, b) => b.localeCompare(a))
          .slice(0, 5);

        // Set the sorted file list directly
        const mappedFiles = sortedFiles.map((name) => ({ name, text: null }));
        setFiles(mappedFiles);
        dispatch(setETBDocumentAC(mappedFiles));

        // Fetch and download the freshest file only
        const freshestFileUrl = `https://zimtra-publica-data.s3.us-east-2.amazonaws.com/${sortedFiles[0]}`;
        const response = await fetch(freshestFileUrl);
        if (response.ok) {
          const blob = await response.blob();
          const text = await blob.text();
          dispatch(setFreshestETBDocumentAC(text));
        }
      } catch (error) {
        console.error("Error fetching or parsing file list:", error);
      }
    };

    if (!ETBDocument) {
      setFileList();
    }
  }, [ETBDocument, dispatch]);

  const helperDownloadFile = (text, label) => {
    const csvContent = text.replace(/\\n/g, "\n").replace(/\\t/g, ",");

    // Convert the formatted text content to a Blob
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element, set its download attribute, and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", label || "download.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadClick = async (fileName) => {
    const fileUrl = `https://zimtra-publica-data.s3.us-east-2.amazonaws.com/${fileName}`;
    try {
      const response = await fetch(fileUrl);
      if (response.ok) {
        const blob = await response.blob();
        const text = await blob.text();
        helperDownloadFile(text, fileName);
        return text;
      }
    } catch (error) {
      console.error(`Failed to fetch file: ${fileName}`, error);
    }
    return null;
  };

  return (
    <div className={cn("page-wrapper", styles.resources)}>
      <h3>Easy to Borrow</h3>
      {files.length > 0 ? (
        <div>
          <strong>Today's ETB list.</strong>&nbsp;
          <span>Start typing symbol name to see exact or partial match.</span>
          {FreshestETBDocument && (
            <AttachmentSearch src={FreshestETBDocument} />
          )}
          <br />
          <div className={styles.body}>
            <span>DOWNLOAD FILES</span>
            <strong>Current</strong>
            <AttachmentDownload
              label={files[0].name}
              onDownloadClick={() => handleDownloadClick(files[0].name)}
            />
            <br />
            <strong>Recent</strong>
            {files.slice(1).map((file) => (
              <AttachmentDownload
                key={crypto.randomUUID()}
                label={file.name}
                onDownloadClick={() => handleDownloadClick(file.name)}
              />
            ))}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Resources;
