import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuContext } from "@lynx/ui-components";
import { Spinner } from "react-bootstrap";

import TableComponent from "ui/ui-kit/react-table/TableComponent";
import constants from "lib/constants";
import { accountThunks } from "actions-thunks/account-thunks";
import { requestsThunks } from "actions-thunks/requests-thunks";
import { requestTableUtils } from "ui/utilities/requestsTableUtils";
import { ModalContext } from "ui/ui-kit/modal/ModalContext";
import SupportStateController from "./state-controller/SupportStateController";
import { NewRequest } from "ui/account-overview/requests/NewRequest";
import useSocketNamespace from "ui/hooks/useSocket";
import useStatistic from "ui/hooks/useStatistic";
import { supportTableUtils } from "ui/ui-kit/react-table/supportTableUtils";

import "./SupportPage.scss";

const { tableFormattedData, setCellClassName, showInOverlay } = requestTableUtils;
const { RequestTypes } = constants;

const SupportPage = () => {
  const { currentAccount } = useSelector((state) => state.accounts);
  const { isInitializedSocket } = useSelector((state) => state.sockets);
  const { requestsItems, riskSettingMetadata } = useSelector(
    (state) => state.requests
  );
  const { showModal, hideModal } = useContext(ModalContext);
  const requestsItemsDataALL = requestsItems?.[RequestTypes.ALL];
  const [visibleItems, setVisibleItems] = useState(requestsItemsDataALL?.length > 10 ? 10 : requestsItemsDataALL?.length);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.utils);

  useSocketNamespace(currentAccount, 'applications', isInitializedSocket);
  useStatistic(currentAccount?._id, "Open page Support");

  useEffect(() => {
    setVisibleItems(requestsItemsDataALL?.length > 10 ? 10 : requestsItemsDataALL?.length)
  }, [requestsItemsDataALL]);

  useEffect(() => {
    if (currentAccount && !!Object.keys(currentAccount).length) {
      dispatch(accountThunks.setAccountExtensionsTC(currentAccount._id));
      dispatch(requestsThunks.getRiskSettingsMetadataTC(currentAccount._id));
      dispatch(requestsThunks.getAccountRequestsTC({
        accID: currentAccount._id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  const renderNoDataBlock = () => {
    return (
      <div className="support-no-data-block">
        <span>No active requests</span>
      </div>
    );
  };

  const cancelRequest = (data) => {
    const id = requestsItemsDataALL?.find(
      (r) => r.sequenceNumber === data.original.id
    )?._id;
    dispatch(
      requestsThunks.updateRequestStatusTC({
        accID: currentAccount._id,
        requestID: id,
        action: "cancel"
      })
    );
  };

  const showInOverlayHandler = (row, isLastItem) => {
    return showInOverlay({
      row,
      isLastItem,
      showModal,
      hideModal,
      cancelRequest,
      MenuContext,
      requestsItemsDataALL,
    });
  };

  return (
    <div className="support-wrapper">
      <h2>Support</h2>
      {loading && <Spinner className="spinner" animation="grow" />}
      {requestsItemsDataALL && (
        <div className="support-table">
          <div className="support-table-header">
            <NewRequest />
          </div>
          <div className="support-table-body">
            <TableComponent
              data={tableFormattedData(
                false,
                requestsItemsDataALL.slice(-visibleItems),
                riskSettingMetadata
              )}
              dateColumns={["created", "updated"]}
              formattingForDateColumns={supportTableUtils.tableTimeFormat}
              showSearch={false}
              requestsItemsDataALL={requestsItemsDataALL}
              setCellClassName={setCellClassName}
              showRowOverlay={showInOverlayHandler}
              itemsToUseSorting={[
                "id",
                "category",
                "type",
                "state",
                "updated",
                "created",
              ]}
              noDataBlock={renderNoDataBlock()}
            />
          </div>
          <SupportStateController
            setVisibleItems={setVisibleItems}
            visibleItems={visibleItems}
            items={requestsItemsDataALL}
          />
        </div>
      )}
    </div>
  );
};

export default SupportPage;
