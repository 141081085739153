import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TO_ACCOUNT_OVERVIEW } from "reducers/types";
import { redirectToLanding } from "actions-thunks/helpers";
import constants from "lib/constants";

import "./not-found.scss";

const NotFound = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const onBackToMainClick = () => {
    if (isAuthenticated) {
      navigate(TO_ACCOUNT_OVERVIEW);
    } else {
      redirectToLanding(constants.loginizationsPaths.LOGOUT)
    }
  }
  return (
    <section className="section-not-found">
      <div className="wrapper">
        <h1 className="zimtra-h1-large">Page not found</h1>
        <span className="back-to-main" onClick={onBackToMainClick}>Back To Main</span>
      </div>
    </section>
  );
};

export default NotFound;
